import gql from "graphql-tag"
import { camelToSnake } from "@/utils"

let API

const getProducts = function(store /*, locale */) {
  return API.Default.query({
    query: gql`
      query BleachSite_GetAllProducts__cached($shopName: String!) {
        productBundles(shopName: $shopName) {
          id
          cmsId
          quantityStatus
          description
          displayTitle
          ecomId
          ecomVariantHandler
          ecomVariantId
          products {
            cmsId
          }
          slug
          productGroup {
            cmsId
          }
          price {
            cents
            currency
          }
          compareAtPrice {
            cents
            currency
          }
          images {
            id
            originalSecureUrl
            metadata
          }
          sku
          waitlist
          sellingPlanGroups {
            sellingPlans {
              shopifyGid
              orderIntervalUnitType
              orderIntervalFrequency
              pricingPolicy {
                adjustmentValue
              }
            }
          }
        }
        giftCards(shopName: $shopName) {
          id
          cmsId
          ecomVariantHandler
          ecomVariantId
          description
          images {
            id
            originalSecureUrl
            metadata
          }
          price {
            cents
            currency
          }
          displayTitle
          sku
          slug
        }
        products(shopName: $shopName) {
          id
          cmsId
          description
          displayTitle
          images {
            id
            originalSecureUrl
            metadata
          }
          slug
          productGroup {
            cmsId
          }
          waitlist
          ecomId
          ecomVariantId
          ecomVariantHandler
          quantityStatus
          averageRating
          numberOfReviews
          price {
            cents
            currency
          }
          compareAtPrice {
            cents
            currency
          }
          sku
          sellingPlanGroups {
            sellingPlans {
              shopifyGid
              orderIntervalUnitType
              orderIntervalFrequency
              pricingPolicy {
                adjustmentValue
              }
            }
          }
        }
      }
    `,
    variables: {
      shopName: store
    }
  })
    .then(res => {
      return {
        products: mapProducts(res.data.products),
        giftCards: mapProducts(res.data.giftCards),
        bundles: mapProducts(res.data.productBundles)
      }
    })
    .catch(error => {
      console.log(error)
    })
}

export default {
  init: _API => {
    API = _API
  },
  methods: {
    getProducts
  }
}

/////////////////////
/////// UTILS ///////
/////////////////////

function mapProducts(products) {
  return products
    .filter(product => !!product)
    .map(product => {
      // Map image field keys to match to Contentful
      let _mappedImages = []
      if (product.images && product.images.length > 0) {
        ;[...product.images].forEach(image => {
          let _mappedImageObj = {}
          Object.keys(image).forEach(key => {
            _mappedImageObj[camelToSnake(key)] = image[key]
          })
          _mappedImages.push(_mappedImageObj)
        })
      }
      // Map BleachAPI reference field objects to match Contentful GQL shape
      let _mappedReferenceFields = {}
      Object.keys(product).forEach(key => {
        // Do it for simple references
        if (product[key] && product[key].cmsId) {
          _mappedReferenceFields[key] = {
            sys: {
              id: product[key].cmsId
            }
          }
        }
        // And for arrays of references
        else if (product[key] && Array.isArray(product[key])) {
          let _arr = []
          product[key].forEach(item => {
            if (item) {
              if (item.cmsId) {
                _arr.push({
                  sys: {
                    id: item.cmsId
                  }
                })
              } else {
                _arr.push(item)
              }
            }
          })
          _mappedReferenceFields[key] = _arr
        }
      })
      return { ...product, ..._mappedReferenceFields, images: _mappedImages }
    })
}
