import gql from "graphql-tag"

let API

const cancelConsultation = function(consultationId) {
  return API.Secure.mutate({
    mutation: gql`
    mutation BleachSite_CancelConsultation {
      cancelConsultation(consultationId: "${consultationId}") {
        result
        errors
      }
    }`
  })
}

/*
  buuid - String
  dates - ["yyyy-mm-dd", "yyyy-mm-dd", ...]
*/
const getConsultationSlots = function(dates) {
  return API.Secure.query({
    fetchPolicy: "no-cache",
    query: gql`
      query {
        topics {
          id
          name
        }
        slots(dates: [${dates.join(", ")}]) {
          id
          startAt
          endAt
          available
          stylist {
            id
            firstName
            lastName
            instagramHandle
            avatarUrl
            bio
          }
          consultation {
            id
            user {
              buuid
            }
            joinUrl
          }
        }
      }
    `
  }).catch(err => console.log(err))
}

const bookSlot = function(slot, topic) {
  return API.Secure.mutate({
    mutation: gql`
      mutation BleachSite_BookConsultation{
        bookConsultation(slotId: "${slot.id}", topicId: "${topic.id}") {
          result
          errors
        }
      } 
    `
  }).catch(err => console.log(err))
}

export default {
  init: _API => {
    API = _API
  },
  methods: {
    getConsultationSlots,
    bookSlot,
    cancelConsultation
  }
}
