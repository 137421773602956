// import { storeLocales } from "@/store/constants"
// import store from "@/store"
import algoliasearch from "algoliasearch/lite"

const algoliaClient = algoliasearch(
  process.env.VUE_APP_ALGOLIA_ID,
  process.env.VUE_APP_ALGOLIA_ACCESS_KEY
)

// let _searchClient = null

export default {
  searchClient: {
    search(req) {
      // for an empty string, don't submit the query
      if (req.every(({ params }) => !params.query || params.query.length < 2)) {
        return Promise.resolve({
          results: req.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0
          }))
        })
      } else {
        return algoliaClient.search(req)
      }
    }
  }
}

// function getAlgoliaIndexes() {
//   if (this.locale == "en-GB") {
//     return process.env.VUE_APP_ALGOLIA_PRODUCTS_INDEX_ENGB
//   } else if (this.locale == "en-US") {
//     return process.env.VUE_APP_ALGOLIA_PRODUCTS_INDEX_ENUS
//   }

//   return process.env.VUE_APP_ALGOLIA_PRODUCTS_INDEX_ENGB
// }
