import gql from "graphql-tag"

let API, store

const getShoppingCartById = function(cartId = "") {
  let requestType = "Default"
  if (store.state.identity.authJWT) {
    requestType = "Secure"
  }
  return API[requestType].query({
    query: gql`
      query BleachSite_GetShoppingCartById {
        shoppingCart(id: "${cartId}")
        {
          id
          lineItems {
            id
            quantity
            ecomVariantId
            customAttributes {
              key
              value
            }
          }
        }
      }
    `
  })
}

const createNewCart = function() {
  return API.Default.mutate({
    mutation: gql`
      mutation BleachSite_CreateNewCart {
        addShoppingCart {
          errors
          result
          shoppingCart {
            id
          }
        }
      }
    `
  })
}

const setCart = function(cartId, lineItems) {
  let requestType = "Default"
  if (store.state.identity.authJWT) {
    requestType = "Secure"
  }

  let lineItemsString = lineItems
    .map(lineItem => {
      let customAttributes = null

      if (lineItem.customData) {
        customAttributes = JSON.stringify(
          Object.keys(lineItem.customData).map(key => ({
            key,
            value: String(lineItem.customData[key])
          }))
        ).replace(/"([^(")"]+)":/g, "$1:")
      }

      return `{
        ecomVariantId: "${lineItem.product.ecomVariantId}",
        quantity: ${lineItem.quantity},
        customAttributes: ${customAttributes}
      },`
    })
    .join(" ")

  return API[requestType].mutate({
    mutation: gql`
      mutation BleachSite_BatchAddItemsToCart {
        setCart(cartId: "${cartId}", lineItems: [${lineItemsString}])
        {
          errors
          result
          shoppingCart {
            id
            lineItems {
              id
              quantity
              ecomVariantId
              customAttributes {
                key
                value
              }
            }
          }
        }
      }
    `
  })
}

const removeItemFromCart = function(cartId, lineItemId, quantity) {
  let requestType = "Default"
  if (store.state.identity.authJWT) {
    requestType = "Secure"
  }

  return API[requestType].mutate({
    mutation: gql`
      mutation BleachSite_RemoveItemFromCart {
        removeLineItem(cartId: "${cartId}", lineItemId: "${lineItemId}", quantity: ${quantity})
        {
          errors
          result
          shoppingCart {
            id
            lineItems {
              id
              quantity
              ecomVariantId
              customAttributes {
                key
                value
              }
            }
          }
        }
      }
    `
  })
}

export default {
  init: (_API, _store) => {
    API = _API
    store = _store
  },
  methods: {
    getShoppingCartById,
    createNewCart,
    setCart,
    removeItemFromCart
  }
}
