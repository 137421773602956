import gql from "graphql-tag"

let API, store

const getReviewSubmissionHash = async function(timeStamp, shopName = null) {
  if (!shopName)
    shopName =
      store && store.state && store.state.locale && store.state.locale.shopName
  let hash
  await API.Secure.mutate({
    mutation: gql`
      mutation BleachSite_GenerateReviewSignature(
        $time_stamp: Int!
        $shop_name: String!
      ) {
        generateReviewSignature(timestamp: $time_stamp ${
          shopName ? ", shopName: $shop_name" : ""
        }) {
          result
          errors
        }
      }
    `,
    variables: {
      time_stamp: timeStamp,
      shop_name: shopName
    }
  })
    .then(res => {
      hash = res.data.generateReviewSignature.result
    })
    .catch(err => {
      console.log(err)
    })
  return hash
}

export default {
  init: (_API, _store) => {
    API = _API
    store = _store
  },
  methods: {
    getReviewSubmissionHash
  }
}
