import gql from "graphql-tag"

let API

const cancelSubscription = function(subscriptionId) {
  return API.Secure.mutate({
    mutation: gql`
      mutation BleachSite_CancelSubscription {
        cancelSubscription(subscriptionId: "${subscriptionId}") {
          result
          errors
        }
      }
    `
  })
}

export default {
  init: _API => {
    API = _API
  },
  methods: {
    cancelSubscription
  }
}
